import config from '@ccsdk/core/config';

export const fetchGlobalNotificationAlerts = async ({ locale, accessToken }) => {
  return fetch(`${config.getGateway('notificationsGateway')}/globalnotification/search`, {
    method: 'POST',
    body: JSON.stringify({
      brand: config.getBrand(),
      locale,
    }),
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/vnd.com.volvo.tisp.notifmgr.notifications.v1.0+json',
      Accept: 'application/vnd.com.volvo.tisp.notifmgr.notifications.v1.0+json',
    },
  });
};

export const fetchGlobalNotificationAlertsOpen = async ({ locale }) => {
  return fetch(`${config.getGateway('notificationsGateway')}/globalnotification/opensearch`, {
    method: 'POST',
    body: JSON.stringify({
      brand: config.getBrand(),
      locale,
    }),
    headers: {
      'Content-Type': 'application/vnd.com.volvo.tisp.notifmgr.notifications.v1.0+json',
      Accept: 'application/vnd.com.volvo.tisp.notifmgr.notifications.v1.0+json',
    },
  });
};
