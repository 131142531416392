import { call, select } from 'redux-saga/effects';
import { selectAccessToken } from '@ccsdk/core/ducks/authentication';
import { selectLocale } from '@ccsdk/core/ducks/userSettings';
import { fetchGlobalNotificationAlerts, fetchGlobalNotificationAlertsOpen } from '../adapters/GlobalNotificationsAdaper';

export function* getGlobalNotificationAlerts() {
  const accessToken = yield select(selectAccessToken);
  const locale = yield select(selectLocale);

  if (accessToken) {
    return yield call(fetchGlobalNotificationAlerts, { locale, accessToken });
  }

  return yield call(fetchGlobalNotificationAlertsOpen, { locale });
}
