import { produce } from 'immer';
import { FETCH_NOTIFICATIONS_ERROR, FETCH_NOTIFICATIONS_SUCCESS, CLOSE_NOTIFICATION_SUCCESS } from './actions';

const initialState = { notifications: [], loaded: false, error: '' };

export default produce((draft, { type, data }) => {
  switch (type) {
    case CLOSE_NOTIFICATION_SUCCESS: {
      draft.notifications = [...(draft.notifications?.filter((notification) => notification.notificationPlatformId !== data.notificationId) || [])];
      break;
    }
    case FETCH_NOTIFICATIONS_SUCCESS: {
      const closedNotifications = JSON.parse(window.localStorage.getItem('closed_notifications')) || [];
      const loadedNotifications = data || [];
      draft.error = '';
      draft.loaded = true;
      draft.notifications = [...loadedNotifications.filter((notification) => !closedNotifications.includes(notification.notificationPlatformId))];
      break;
    }
    case FETCH_NOTIFICATIONS_ERROR: {
      draft.loaded = false;
      draft.notifications = [];
      draft.error = data;
      break;
    }
    default:
      break;
  }
}, initialState);
