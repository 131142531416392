import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { FETCH_NOTIFICATIONS_SUCCESS, FETCH_NOTIFICATIONS_ERROR, FETCH_NOTIFICATIONS, CLOSE_NOTIFICATION, CLOSE_NOTIFICATION_SUCCESS } from '../actions';
import { getGlobalNotificationAlerts } from './requestSagas';

export function* fetchGlobalNotificationAlertsSaga() {
  try {
    const resp = yield call(getGlobalNotificationAlerts);
    const data = yield resp.json();
    yield put({ type: FETCH_NOTIFICATIONS_SUCCESS, data: data.notificationGlobalResponse });
  } catch (error) {
    yield put({ type: FETCH_NOTIFICATIONS_ERROR, data: error.toString() });
  }
}

export function* closeGlobalNotification({ data: { notificationId } }) {
  try {
    const closed = JSON.parse(window.localStorage.getItem('closed_notifications')) || [];
    window.localStorage.setItem('closed_notifications', JSON.stringify([...closed, notificationId]));
    yield put({ type: CLOSE_NOTIFICATION_SUCCESS, data: { notificationId } });
  } catch (error) {
    yield put({ type: FETCH_NOTIFICATIONS_ERROR, data: error.toString() });
  }
}

export default function* globalNotificationAlert() {
  yield takeLatest(FETCH_NOTIFICATIONS, fetchGlobalNotificationAlertsSaga);
  yield takeEvery(CLOSE_NOTIFICATION, closeGlobalNotification);
}
