import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

export const incidentAPI = createApi({
  reducerPath: 'incidentBannerAPI',
  baseQuery: retry(
    fetchBaseQuery({
      prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.accessToken;
        if (token) {
          headers.set('authorization', `Bearer ${token}`);
        }
        headers.set('Content-Type', 'application/x.volvogroup.com.incident.v1.0+json');
        headers.set('Accept', 'application/x.volvogroup.com.incident.v1.0+json');
        return headers;
      },
    }),
    { maxRetries: 5 },
  ),
  endpoints: (builder) => ({
    getIncidents: builder.query({
      query: ({ baseUrl, brand, locale }) => `${baseUrl}/incidents/search?brand=${brand}&locale=${locale}`,
    }),
    getIncidentsNotLoggedIn: builder.query({
      query: ({ baseUrl, brand, locale }) => `${baseUrl}/incidents/opensearch?brand=${brand}&locale=${locale}`,
    }),
  }),
});

export const { useGetIncidentsQuery, useGetIncidentsNotLoggedInQuery } = incidentAPI;
