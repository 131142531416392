import authReducer from '@ccsdk/core/ducks/authentication';
import queriesReducer from '@ccsdk/core/ducks/queries';
import errorReporterReducer from '@ccsdk/core/ducks/errorReporter';
import configReducer from '@ccsdk/core/ducks/config';
import statusReducer from '@ccsdk/core/ducks/status';
import cloudfrontReducer from '@ccsdk/core/ducks/cloudfront';
import userSettingsReducer from '@ccsdk/core/ducks/userSettings';
import actionStatusReducer from '@ccsdk/core/ducks/actionStatus';
import errorPopupReducer from '@ccsdk/core/ducks/errorPopup';
import dynamicModulesReducer from '@ccsdk/core/ducks/dynamicModules';
import emergencyReducer from '@ccsdk/core/ducks/emergency';
import globalNotificationsReducer from '~/components/GlobalNotifications/reducer';

const reducers = {
  auth: authReducer,
  queries: queriesReducer,
  errorReporter: errorReporterReducer,
  config: configReducer,
  status: statusReducer,
  cloudfront: cloudfrontReducer,
  userSettings: userSettingsReducer,
  actionStatus: actionStatusReducer,
  errorPopup: errorPopupReducer,
  dynamicModules: dynamicModulesReducer,
  globalnotification: globalNotificationsReducer,
  emergency: emergencyReducer,
};

export default reducers;
