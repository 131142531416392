import { isLoginMode } from '@ccsdk/core/utils/url';

// Array to store all public sections.
const publicSections = ['/charge', '/icons', '/support/cookiesstatement', '/support/systemstatus'];

// Function to check if the current section is public.
const isPublicPath = (pathname) => {
  return publicSections.includes(pathname);
};

// FIXME: This is temporary, we need a much better way to centrally configure which pages
// should be public and which should be private.
export const isPublicSection = () => {
  return isLoginMode() || isPublicPath(location.pathname);
};
